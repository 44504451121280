import React from 'react';
import { graphql } from 'gatsby';

import { BusinessLandingBlogData } from 'components/BusinessComponents/types';
import SEO from 'components/SEO';
import {
  BusinessLandingProvider,
  businessLandingPageData as pageData,
  PageWrapper,
  Hero,
  Header,
  TrustedBy,
  Succeed,
  Solutions,
  Experts,
  Schedule,
  Resources,
  Footer,
} from 'components/BusinessComponents';

export const query = graphql`
  query BusinessPageQuery {
    allPrismicAffiliatePage {
      nodes {
        dataRaw
      }
    }
    allPrismicBusinessBlogPage {
      nodes {
        data {
          author_name {
            text
          }
          post_title {
            text
          }
          post_image {
            url
            alt
          }
        }
        url
      }
    }
  }
`;

const Business = ({ data }) => {
  const businessBlogData: BusinessLandingBlogData[] = data.allPrismicBusinessBlogPage.nodes.map(
    ({ data, url }) => ({ url, ...data })
  );

  return (
    <BusinessLandingProvider data={{ ...pageData, resources: businessBlogData }}>
      <SEO
        pageTitle={pageData?.page_title?.text}
        ogTitle={pageData?.og_title?.text}
        twitterTitle={pageData?.twitter_title?.text}
        pageDescription={pageData?.page_description?.text}
        ogDescription={pageData?.og_description?.text}
        twitterDescription={pageData?.twitter_description?.text}
        pageImage={pageData?.page_image?.url}
        pageImageAlt={pageData?.page_image?.alt}
        twitterImage={pageData?.twitter_image?.url}
        twitterImageAlt={pageData?.twitter_image?.alt}
        ogImage={pageData?.og_image?.url}
        ogImageAlt={pageData?.og_image?.alt}
      />
      <PageWrapper>
        <Header />
        <Hero />
        <TrustedBy />
        <Succeed />
        <Solutions />
        <Experts />
        <Schedule />
        <Resources />
        <Footer />
      </PageWrapper>
    </BusinessLandingProvider>
  );
};

export default Business;
