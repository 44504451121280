import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

import { INTERNAL_LINKS } from 'utils/constants';
import logo from 'assets/others/logo.png';

const SEO = ({
  pageTitle = '',
  ogTitle = '',
  twitterTitle = '',
  pageDescription = '',
  ogDescription = '',
  twitterDescription = '',
  pageImage = '',
  ogImage = '',
  twitterImage = '',
  pageImageAlt = '',
  ogImageAlt = '',
  twitterImageAlt = '',
  twitterCard = 'summary_large_image',
  twitterAuthor = '@your_twitter_name',
  twitterSite = '@your_twitter_name',
  noindex = false,
}) => {
  const { pathname } = useLocation();

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          url
          imageAlt
          description
          titleTemplate
        }
      }
    }
  `);

  const defaults = data.site.siteMetadata;

  if (defaults.url === '' && typeof window !== 'undefined') {
    defaults.url = window.location.origin;
  }

  if (defaults.url === '') {
    console.error('Please set a baseUrl in your site metadata');

    return null;
  }

  const seo = {
    url: `${defaults.url}${pathname}`,
    title: pageTitle || defaults.title,
    ogTitle: ogTitle || pageTitle || defaults.title,
    twitterTitle: twitterTitle || pageTitle || defaults.title,
    description: pageDescription || defaults.description,
    ogDescription: ogDescription || pageDescription || defaults.description,
    twitterDescription: twitterDescription || pageDescription || defaults.description,
    image: `${pageImage || logo}`,
    ogImage: `${ogImage || pageImage || logo}`,
    twitterImage: `${twitterImage || pageImage || logo}`,
    imageAlt: pageImageAlt || defaults.imageAlt,
    ogImageAlt: ogImageAlt || pageImageAlt || defaults.imageAlt,
    twitterImageAlt: twitterImageAlt || pageImageAlt || defaults.imageAlt,
  };

  return (
    <Helmet
      title={seo.title}
      titleTemplate={pathname === INTERNAL_LINKS.HOME && defaults.titleTemplate}
    >
      <title>{seo.title}</title>
      {seo.url && <link rel="canonical" href={seo.url} />}
      {seo.description && <meta name="description" content={seo.description} />}
      <meta name="robots" content={noindex ? 'noindex' : 'index, follow'} />
      {seo.image && <meta name="image" content={seo.image} />}

      {seo.url && <meta property="og:url" content={seo.url} />}
      <meta property="og:site_name" content="Yours App" />
      <meta property="og:type" content="website" />
      {seo.ogTitle && <meta property="og:title" content={seo.ogTitle} />}
      {seo.ogDescription && <meta property="og:description" content={seo.ogDescription} />}
      {seo.ogImage && <meta property="og:image" content={seo.ogImage} />}

      <meta property="fb:app_id" content={process.env.GATSBY_FB_APP_ID} />

      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:site" content={twitterSite} />
      <meta name="twitter:creator" content={twitterAuthor} />
      {seo.twitterTitle && <meta name="twitter:title" content={seo.twitterTitle} />}
      {seo.twitterImage && <meta name="twitter:image" content={seo.twitterImage} />}
      {seo.twitterImageAlt && <meta name="twitter:image:alt" content={seo.twitterImageAlt} />}
      {seo.twitterDescription && (
        <meta name="twitter:description" content={seo.twitterDescription} />
      )}
    </Helmet>
  );
};

export default SEO;
